.favorite-location {
	max-width: 450px;
	margin-top: 2rem;
	background-color: #08393b;
	color: #fff;
	border-radius: 0.6rem;
	text-align: center;
	box-shadow: 0rem 1rem 2rem transparentize(#000000, 0.75);
	line-height: 1.5rem;

	.inner {
		background: darken(#1a565a, 0.5);
		border-radius: 0.6rem;
		text-align: left;
		padding: 1rem;

		.location {
			font-weight: bold;
		}
	}
}

.test-location a {
	color: #8ce7eb !important;
	&:hover {
		color: #fff !important;
	}
}
.filler-words {
	opacity: 0.5;
	text-align: right;
}

.queue-wait {
	padding: 1rem;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.queue-wait__name {
	font-weight: bold;
}

.certificate-validation-container {
	.certificate-card.valid {
		background-color: #1a565a;
		background-image: linear-gradient(130deg, #19835a, #1a565a) !important;
		color: #fff;
	}
	.certificate-card.invalid {
		color: #dc3545;
		border-color: #dc3545;
	}
	hr {
		border: 1px solid #fff;
	}
	.date {
		font-weight: bolder;
	}
	button {
		background-color: #a31f34 !important;
	}
}

.mit-bounce {
	background-color: #a31f34 !important;
}

.atlas-theme {
	@media screen and (max-width: 566px) {
		.mainbar {
			// order: 1 !important;
			// box-shadow: 0rem -1rem 2rem rgba(0, 0, 0, 0.3) !important;
			.basic-layout {
				width: 100%;
			}
		}
		.header {
			h4 {
				opacity: 1 !important;
				font-size: initial !important;
				margin-left: initial !important;
				margin-top: initial !important;
				font-weight: 600 !important;
			}
		}
		.sidebar {
			.logo {
				width: unset !important;
				height: unset !important;
				padding: 1rem !important;
				position: unset !important;

				svg {
					display: block;
				}
			}
		}
	}
	.appSubName {
		display: none !important;
	}
	.header {
		h4 {
			opacity: 0.5;
			font-size: 0.8rem;
			margin-left: -10px;
			margin-top: -15px;
			font-weight: 200;
		}
	}
}

// @import url('https://use.typekit.net/mrl1qhh.css');
// @import "../../node_modules/bootstrap/scss/bootstrap";
// @import '../../node_modules/react-bootstrap-typeahead/css/Typeahead.css';

@import './vars';
@import './global';

@import './layout';
@import './typography';
@import './buttons';
@import './modal';
@import './table';

// Components
@import './queue-lengths';
@import './certificateVerification';

.vaccine .basic-layout .content-area {
	background-image: linear-gradient(130deg, #19835a, #1a565a) !important;
}

.medical-dark .basic-layout .lifted-nav .nav-item.active,
.medical-dark .basic-layout .lifted-nav .nav-link.active {
	background-color: rgba(0, 0, 0, 0.1) !important;
}

.large-icon,
.bigicon {
	i {
		font-size: 3.5rem;
		margin-bottom: 1rem;
		margin-top: 1rem;
		width: 4rem;
	}
}

textarea.form-control {
	color: #fff;
}

.form-control:active {
	box-shadow: 0 0 0 0.2rem rgba(25, 86, 90, 0.4);
}

.modal .form-control:focus {
	background: none !important;
	color: #fff !important;
}

.list-group-item.active {
	pointer-events: inherit;
}

.vaccine .medical-dark div.text-center a {
	color: #fff !important;
}

.single-question {
	display: flex;
	.question-text {
		flex-grow: 1;
		padding-right: 0.5rem;
	}
	.switch-options {
		text-align: right;
		white-space: nowrap;
	}

	border-bottom: 1px solid #ddd;
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;
}

.splash-icon {
	width: 5rem;
	margin-bottom: 1rem;
}

.smallMarginTop {
	margin-top: 2rem;
}

.splash-container .medical-dark {
	background: none;
}
.drawer .footer {
	//dangerous code
	display: none;
}
.medical .list-group-item:hover {
	background: rgba(25, 131, 90, 0.25) !important;
	border-radius: 0.5rem !important;
}
.list-group-item i {
	font-weight: bold;
}
.list-group-flush .list-group-item {
	margin-bottom: 0rem;
}

.message a {
	color: #fff !important;
}

.medical-dark .survey a {
	color: #fff !important;
	border: 1px solid rgba(255, 255, 255, 0.25);
}

.medical-dark .survey .fa-circle {
	color: rgba(255, 255, 255, 0.2) !important;
}

.survey .list-group-item:hover {
	background: #1a565a;
}
.survey .list-group-item.active,
.survey .list-group-item.active:focus {
	background: rgba(255, 255, 255, 0.25) !important;
	border: 1px solid rgba(255, 255, 255, 0.5) !important;
	outline: none !important;
	box-shadow: none !important;
}

.profile-pic {
	width: 3rem;
	height: 3rem;
	display: inline-block;
	border-radius: 2rem;
	background: #eee;
	overflow: hidden;

	img {
		width: 3rem;
		height: 3rem;
		object-fit: cover;
	}
}

.profile-pic i {
	margin: 1rem;
}

.meh {
	display: flex;
}

.card-dashed {
	border-style: dashed !important;
}

.contactname {
	height: 3rem;
	margin-left: 1rem;
	display: inline-block;
	font-weight: 200;
	div {
		padding-top: 0.1rem;
	}
}

.light-text {
	opacity: 0.5;
}

.medical .delete {
	.btn-outline-secondary {
		color: #ed1a40 !important;
		border-color: #ed1a40 !important;
		&:hover {
			background-color: #ed1a40 !important;
			color: #fff !important;
		}
	}
}

.nobackground {
	background: none !important;
}
.calendar,
.dob {
	.DatePicker {
		width: 100%;
	}
}
.mitid {
	label {
		margin: 0rem;
		padding-top: 0.33rem;
		padding-bottom: 0.25rem;
	}
}
.alert.alert-danger {
	background-color: #64b496 !important;
}
.greenlinks {
	color: #1a565a;
	text-decoration: underline;
	&:hover {
		color: #19835a;
	}
}
.toggletext {
	margin-top: 1.5rem;
	padding-left: 0.5rem;
}
.phone {
}
.email {
}
.medical-patient {
	.col-sm-4 {
		//padding-top:0rem;
	}
}
.comorb {
	margin-bottom: -0.5rem !important;
	.col-sm-4 {
		//padding-top:0rem;
	}
	input {
		width: 30%;
		text-align: right;
	}
}
.when {
	margin-bottom: -0.5rem;
}

.meh {
	align-items: center;
	display: flex;
	div {
		display: flex;
		align-items: center;
	}
}

.family-list {
	.list-group-item {
		&:hover {
			background: rgba(25, 131, 90, 0.25);
			border-radius: 0.5rem;
		}
		&:focus {
			border-color: rgba(0, 0, 0, 0.125) !important;
		}
		&.active {
			background: none !important;
			color: #000 !important;
			border-radius: 0rem;
			border-color: rgba(0, 0, 0, 0.125) !important;
			pointer-events: inherit;
		}
	}
	.list-group-item.active:focus {
		background: none !important;
		color: #000 !important;
		border-radius: 0rem;
		border-color: rgba(0, 0, 0, 0.125) !important;
	}
	.list-group-item.active:hover {
		background: rgba(25, 131, 90, 0.25) !important;
		border-radius: 0.5rem !important;
	}
}

@media (min-width: 576px) {
	.extra-q {
		margin-top: -1.5rem;
	}
	.prescreen-q {
		width: 90%;
		margin-left: 5%;
		label {
			text-align: left !important;
		}
		.col-sm-4 {
			-ms-flex: 0 0 60%;
			flex: 0 0 60%;
			max-width: 60%;
			text-align: left !important;
		}
		.col-sm-8 {
			-ms-flex: 0 0 40%;
			flex: 0 0 40%;
			max-width: 40%;
		}
	}
	.prescreen-q-more {
		width: 80%;
		margin-left: 10%;
		.form-group {
			margin-bottom: 0.6rem;
		}
	}
}

@media (max-width: 575px) {
	.container {
		margin: 0rem !important;
		padding: 0rem !important;
	}
	.profile-pic {
		display: none !important;
	}
	.col-sm-4 {
		padding-bottom: 5px !important;
	}
	.comorb {
		margin-bottom: 0rem !important;
	}
	.extra-q span {
		text-align: left !important;
	}
	.when {
		margin-bottom: 0rem;
	}
	.role {
		margin-bottom: 0rem !important;
	}
}

.medical-dark .content-sm .btn-primary:focus {
	// The accessibility killer
	background-color: #175c5f !important;
	border-color: #175c5f !important;
	color: #fff;
	box-shadow: 0rem 0.75rem 1.5rem rgba(0, 0, 0, 15%);
}

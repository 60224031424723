.travel-list {
	//start - need to take some of these to medical-dark theme
	.list-group {
		.list-group-item.active {
			border-color: #08393b;
			&:focus {
				background-color: #08393b !important;
			}
		}
		.list-group-item {
			border-color: rgba(255, 255, 255, 0.125);

			&:hover {
				background-color: #08393b;
				transition: none;
			}
		}
	}

	.list-group .primary,
	.list-group .text-right,
	.list-group .additional {
		color: #ffffff !important;
	}

	.list-group-item.active {
		pointer-events: inherit;
	}
	.list-group-item:focus {
		background-color: #08393b;
	}
}

.medical-dark {
	.disabled.list-group-item {
		background-color: transparent;
		opacity: 0.33;
		pointer-events: none;
	}
}

.error.card {
	color: #fff !important;
}

.basic-layout .layout-wrapper .navbar-lift {
	top: 0rem !important;
	margin-bottom: 0rem !important;
}

.empty-image {
	margin-top: -1rem;
}

.infocards .card {
	background: #08393b55;
	border: none;

	ul {
		padding-left: 1rem;
	}
}

.list-group-item .badge {
	position: absolute;
	right: 7rem;
	top: 1rem;
	background: #e3124b;
	font-weight: normal;
	color: #fff !important;
}
.medical.drawer .canceloption {
	.btn {
		float: right;
		margin-top: -3.1rem;
	}

	.btn-outline-secondary {
		color: #e3124b !important;
		border-color: #e3124b !important;
	}
	.btn-outline-secondary:hover,
	.btn-outline-secondary:active,
	.btn-outline-secondary:focus {
		background-color: #e3124b !important;
		border-color: #e3124b !important;
		color: #fff !important;
	}
}

.modal-footer {
	color: #777;
	float: left;
}

.alert-danger a {
	color: #fff !important;
	text-decoration: underline;
}

.travel-list .list-group .list-group-item.active {
	border-color: rgba(255, 255, 255, 0.125);
	background: none;
}

.table1 {
	tr {
		border-bottom: 1px solid #ccc;
	}
	td {
		vertical-align: top;
		padding: 0.5rem;
	}
	td:nth-child(2) {
		background: #eee;
	}
	td:nth-child(4) {
		background: #eee;
	}
}
.table2 {
	td {
		padding: 0.5rem;
	}
}

.modal .nav-link {
	color: #08393b !important;
}

.basic-layout .layout-wrapper .header {
	height: 7rem !important;
}

.basic-layout .content-area {
	height: calc(100% - 7rem) !important;
	min-height: calc(100vh - 7rem) !important;
}

.modal {
	.btn-outline-secondary {
		color: #08393b !important;
		border-color: #194c4e !important;
		&:hover {
			background: #08393b !important;
			color: #fff;
		}
	}
}

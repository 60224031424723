.common-table {
	width: 100%;
}

.common-table {
	th {
		padding: 0.5rem 1rem;
	}
	tr {
		margin: 1rem 0.5rem;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);

		td:last-child,
		th:last-child {
			text-align: right;
		}

		td:nth-child(2) {
			div {
				padding-left: 0rem;
				margin-left: 0rem;
			}
		}

		th:nth-child(2) {
			padding-left: 0rem;
			margin-left: 0rem;
		}
	}
	div {
		margin: 0.25rem;
		padding: 0.25rem 0.75rem;
		display: inline-block;
		text-align: center;
	}

	.negative {
		border-radius: 0.5rem;
		background: green;
		padding: 0.25rem 0.75rem;
		text-align: center;
		display: inline-block;
		margin: 0.25rem;
	}
	.positive {
		border-radius: 0.5rem;
		background: red;
		padding: 0.25rem 0.75rem;
		text-align: center;
		display: inline-block;
		margin: 0.25rem;
	}

	.flu {
		border-radius: 0.5rem;
		background: #0079d1;
		padding: 0.25rem 0.75rem;
		text-align: center;
		display: inline-block;
		margin: 0.25rem;
	}

	.medical-dark {
		background: transparent;
	}

	a {
		color: #8ce7eb !important;
	}

	.busy-indicator.btn .loader {
		padding: 0;
		margin: 0;
		right: 0.2rem;

		.pulsate-loading {
			padding: 0 !important;
			color: #ffffff !important;
			border: 3px solid #ffffff !important;
		}
	}
}
